import { useEffect, useState } from 'react';
import { SmartProcesses } from '../../constants/smart-processes';

export const useEventInfo = (BX24, eventId) => {
  const [event, setEvent] = useState();

  useEffect(() => {
    BX24.callMethod(
      'crm.item.get',
      {
        entityTypeId: SmartProcesses.event.id,
        id: eventId,
      },
      function (response) {
        if (response.error()) {
          console.error('event request failed' + response.error());

          return null;
        } else {
          setEvent(response);
        }
      },
    );
  }, [eventId]);

  return event;
};
