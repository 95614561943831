import React from 'react';
import styles from './styles.module.css';
import { useListenersInfo } from '../../hooks/listener';
import { ListenerRow } from '../listener-row/listener-row';

export const ListenersTable = ({ lib, lead }) => {
  const contactId = lead.CONTACT_ID;


  const listenersInfo = useListenersInfo(lib, contactId);

  if (!contactId) {
    return (
    <table className={styles['custom-listeners-landpro-table']}>
      <thead>
      <tr className={styles['custom-listeners-landpro-tr']}>
        <td className={styles['custom-listeners-landpro-td']}>
          <div className={styles['custom-listeners-landpro-td-inner-div']}>
            <p>У данного лида отсутствует связь с контактом</p>
          </div>
        </td>
      </tr>
      </thead>
    </table>
    )
  }

  if (!listenersInfo) {
    return <div>Loading...</div>;
  }

  const listeners = listenersInfo.answer.result.items;

  return (
    <table className={styles['custom-listeners-landpro-table']}>
      <thead>
        {listeners.length ? (
          <>
            <tr className={styles['custom-listeners-landpro-tr']}>
              <td className={styles['custom-listeners-landpro-td']}>
                <div
                  className={styles['custom-listeners-landpro-td-inner-div']}
                >
                  <p>Слушатель</p>
                </div>
              </td>
              <td className={styles['custom-listeners-landpro-td']}>
                <div
                  className={styles['custom-listeners-landpro-td-inner-div']}
                >
                  <p>Мероприятие</p>
                </div>
              </td>
              <td className={styles['custom-listeners-landpro-td']}>
                <div
                  className={styles['custom-listeners-landpro-td-inner-div']}
                >
                  <p>Стадия</p>
                </div>
              </td>
            </tr>
            {listeners.map((listener) => (
              <ListenerRow
                lib={lib}
                key={listener.id}
                listener={listener}
                className={styles.review}
              />
            ))}
          </>
        ) : (
          <tr className={styles['custom-listeners-landpro-tr']}>
            <td className={styles['custom-listeners-landpro-td']}>
              <div className={styles['custom-listeners-landpro-td-inner-div']}>
                <p>Нет слушателей</p>
              </div>
            </td>
          </tr>
        )}
      </thead>
    </table>
  );
};
