import { useEffect, useState } from 'react';
import { SmartProcesses } from '../../constants/smart-processes';

export const useListenersInfo = (BX24, contactId) => {
  const [listeners, setListeners] = useState();

  useEffect(() => {
    if (!contactId) {
      return null;
    }

    BX24.callMethod(
      'crm.item.list',
      {
        entityTypeId: SmartProcesses.listener.id,
        filter: { contactId: contactId },
      },
      function (response) {
        if (response.error()) {
          console.error('listeners request failed' + response.error());

          return null;
        } else {
          setListeners(response);
        }
      },
    );
  }, [contactId]);

  return listeners;
};
