import React from 'react';
import { useEventInfo } from '../../hooks/event';
import styles from './styles.module.css';
import { SmartProcesses } from '../../constants/smart-processes';
import { useStatusInfo } from '../../hooks/status';
import { useDomain } from '../../hooks/B24-lib/useDomain';

export const ListenerRow = ({ lib, listener }) => {
  const eventInfo = useEventInfo(lib, listener.parentId157);
  const statusInfo = useStatusInfo(lib, listener.stageId);
  const domain = useDomain(lib);

  if (!eventInfo || !statusInfo) {
    return <div>Loading...</div>;
  }

  const event = eventInfo.answer.result?.item;
  const status = statusInfo.answer.result?.find(
    (status) => status.STATUS_ID === listener.stageId,
  );

  const listenerLink = `https://${domain}/page/uchebnyy_tsentr/slushatel/type/${SmartProcesses.listener.id}/details/${listener.id}/`;
  const eventLink = `https://${domain}/page/uchebnyy_tsentr/meropriyatie/type/${SmartProcesses.event.id}/details/${event.id}/`;

  return (
    <tr className={styles['custom-listeners-landpro-tr']}>
      <td className={styles['custom-listeners-landpro-td']}>
        <div className={styles['custom-listeners-landpro-td-inner-div-link']}>
          <a target="_blank" href={listenerLink} rel="noreferrer">
            {listener.title}
          </a>
        </div>
      </td>
      <td className={styles['custom-listeners-landpro-td']}>
        <div className={styles['custom-listeners-landpro-td-inner-div-link']}>
          <a target="_blank" href={eventLink} rel="noreferrer">
            {event?.title || 'Не найдено'}.
          </a>
        </div>
      </td>
      <td className={styles['custom-listeners-landpro-td']}>
        <div className={styles['custom-listeners-landpro-td-inner-div']}>
          <p>{status?.NAME || 'Не найдена'}</p>
        </div>
      </td>
    </tr>
  );
};
