import React from 'react';
import styles from './styles.module.css';
import { useLeadInfo } from '../../hooks/lead';
import { ListenersTable } from '../../components/listeners-table/listeners-table';
import { useCurrentInfo } from '../../hooks/B24-lib/useCurrentInfo';

export const Listeners = ({ lib }) => {
  const currentInfo = useCurrentInfo(lib);
  const leadId = currentInfo.options?.ID;
  const leadInfo = useLeadInfo(lib, leadId);

  if (!leadInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.root}>
      <ListenersTable lead={leadInfo.answer.result} lib={lib} />
    </div>
  );
};
