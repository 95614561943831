import { useEffect, useState } from 'react';

export const useLeadInfo = (BX24, leadId) => {
  const [lead, setLead] = useState();
  useEffect(() => {
    BX24.callMethod(
      'crm.lead.get',
      {
        id: leadId,
      },
      function (response) {
        if (response.error()) {
          console.error('lead request failed' + response.error());

          return null;
        } else {
          setLead(response);
        }
      },
    );
  }, [leadId]);

  return lead;
};
