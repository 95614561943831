import { useState, useEffect } from 'react';

export const useB24Lib = (lib) => {
  const [savedLib, setLib] = useState({});
  useEffect(() => {
    const script = document.createElement('script');

    script.src = lib.url;
    script.onload = () => {
      window[lib.name].init(function () {
        window[lib.name].installFinish();
        setLib({ [lib.name]: window[lib.name] });
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [lib]);

  return savedLib;
};
