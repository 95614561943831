import { useEffect, useState } from 'react';

export const useStatusInfo = (BX24, statusId) => {
  const [status, setStatus] = useState();

  useEffect(() => {
    BX24.callMethod(
      'crm.status.list',
      {
        filter: { STATUS_ID: statusId },
      },
      function (response) {
        if (response.error()) {
          console.error('status request failed' + response.error());

          return null;
        } else {
          setStatus(response);
        }
      },
    );
  }, [statusId]);

  return status;
};
