import React from 'react';
import { Listeners } from './pages/listeners/listeners';
import { useB24Lib } from './hooks/B24-lib/useB24Lib';
import { Libs } from './constants/libs';

export const App = () => {
  let { BX24 } = useB24Lib(Libs.BX24);

  if (!BX24) {
    return <div>Loading...</div>;
  }

  return <Listeners lib={BX24} />;
};
